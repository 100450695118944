<template>

  <div>

    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'GiftCards'">
      <template v-slot:right-end>
        <div class="vx-row">
          <vs-button class="alpha-clear" v-if="selectedAlpha" color="warning" type="flat"
                     icon-pack="feather" icon="icon-x" size="small"
                     @click="clearSelectedAlpha()"></vs-button>
          <vs-pagination-modified v-model="selectedAlphaCurrentX" :total="26" :max="26"
                                  v-on:change="pageChanged"></vs-pagination-modified>
        </div>
      </template>
    </breadcrumb-base>


    <transition name="fade">
      <div class="vs-row">
        <div class="vs-col px-4 py-3 flex md:w-full">
          <div class="vs-row w-full">
            <vs-input class=" m-5 w-full is-label-placeholder pr-12" icon-pack="feather"
                      icon="icon-search"
                      :placeholder="$t('Search')" v-model="searchText"/>


            <!--    Empty List State -->
            <transition name="fade">
              <div class="py-6 flex w-full bg-img" v-if="giftCards.length === 0 && !errorFetching">
                <div
                  class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                  <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                       class="mx-auto mb-4 max-w-full">-->
                  <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
                </div>
              </div>
            </transition>

            <div class="vs-col px-4 py-3 flex md:w-full">
              <div class="vs-row w-full">
                <template v-for="(card, index) in giftCards">
                  <div class=" w-full md:w-1/3 lg:w-1/5 xl:w-1/5 2xl:w-1/6 vs-col px-2 pb-4">
                    <vx-card class="cursor-pointer custom-card" style="" @click="onProductSelected(card)">
                      <img class="card-image" :src="card.product_image"
                           :alt="card.product_name"/>
                    </vx-card>
                    <p class="text-center pt-2"><strong>{{ card.product_name }}</strong></p>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>


    <div>
      <vs-sidebar v-if="showProductDialog" click-not-close position-right parent="body"
                  default-index="1" color="primary" class="gift-cards-side-bar custom-card-sidebar items-no-padding"
                  spacer v-model="showProductDialog">
        <div class="mt-6 flex items-center justify-between px-6">
          <vs-avatar color="danger" @click="onCloseProductDialog()" size="large" icon="clear">
          </vs-avatar>
<!--          <feather-icon icon="XIcon"  class="cursor-pointer"></feather-icon>-->
        </div>
<!--        <vx-modal v-if="showProductDialog" @close="onCloseProductDialog" component-class="modal-container-7">-->
          <div>
            <div class="p-6 flex flex-centered" v-if="!showPanInput" style="overflow-y: scroll;height: calc(100vh - 100px);">
              <div class="vs-row justify-center col-mx-auto  w-full">
                <div class="vx-col text-center">
                  <img class="modal-card-image"
                       :src="selectedProduct.product_image"
                       :alt="selectedProduct.product_name"/>
                  <div class="p-3"></div>
                </div>
                <h3 class="mx-3 py-4">{{ $t('SelectDenomination') }}</h3>
                <div class="flex flex-wrap w-full">

                  <div class="vs-row">
                    <div class="vs-col mx-3 single-cash-card"
                         v-for="(product, index) in selectedProduct.product_configurations">
                      <vx-card @click="setProductConfigSelected(index)" class="mb-3 mt-3 mr-5"
                               v-bind:class="{ 'product-selected': selectedConfigIndex == index }" >
                        <div class="vx-row vs-align-center text-center">
                          <h1 class="mx-3 item-card-text">{{ product.value | germanNumberFormat }}</h1>
<!--                          <vs-spacer></vs-spacer>-->
<!--                          <h3 class="mx-3 item-card-profit">-->
<!--                            {{ $t('Profit') }} {{ calculateProfit(product.value, product.discount)  | germanNumberFormat}}-->
<!--                          </h3>-->
                        </div>
                      </vx-card>
                    </div>
                  </div>
<!--                  <div class="md:w-full lg:w-1/2 p-5" v-for="(product, index) in selectedProduct.product_configurations">-->
<!--                    <vx-card class="rounded-md"  v-bind:class="{ 'product-selected': selectedConfigIndex == index }"  @click="setProductConfigSelected(index)">-->
<!--                      <div class="vx-row vs-align-center text-center">-->
<!--                        <h1 class="mx-3 item-card-text">{{ product.value | germanNumberFormat }}</h1>-->
<!--                        <vs-spacer></vs-spacer>-->
<!--                        <h3 class="mx-3 item-card-profit">{{ $t('Profit') }} {{ calculateProfit(product.value, product.discount)  | germanNumberFormat}}</h3>-->
<!--                      </div>-->
<!--                    </vx-card>-->
<!--                  </div>-->
                </div>

                <div class="py-4"></div>
                <vs-input autofocus class="w-full gift-input-holder" type="text"
                          v-validate="'required'" validate-on="change"
                          :label-placeholder="$t('EnterBachNo')" v-model="giftCardBatchNo"/>
                <div class="py-8"></div>

                <div class="card-billing-text mx-5 vs-row w-full">
<!--                  <div class="  vs-col pr-3 border-0 md:border-r border-solid border-grey-light">-->
<!--                    <p>{{ $t('BuyingPrice') }} <span class="card-billing-info">{{ (totalBill - profit )| germanNumberFormat }}</span></p>-->
<!--                  </div>-->
<!--                  <vs-spacer></vs-spacer>-->
<!--                  <div class=" pl-3 vs-col pr-3 border-0 md:border-r border-solid border-grey-light">-->
<!--                    <p>{{ $t('BuyingDiscount') }} <span class="card-billing-info">{{ discount }} %</span>-->
<!--                    </p>-->
<!--                  </div>-->
<!--                  <vs-spacer></vs-spacer>-->
                  <div class="vs-col w-full text-center">
                    <p>{{ $t('SellingPrice') }} <span class="card-billing-info">{{ totalBill | germanNumberFormat }}</span>
                    </p>
                  </div>
<!--                  <vs-spacer></vs-spacer>-->
<!--                  <div class=" pl-3 vs-col ">-->
<!--                    <p>{{ $t('Profit') }} <span class="card-billing-info">{{ profit | germanNumberFormat }}</span></p>-->
<!--                  </div>-->
                </div>

                <div class="vs-row flex justify-center py-8">
                  <vs-button class="ml-4  mr-4" color="danger" @click="onCloseProductDialog()">{{
                      $t('Close')
                    }}
                  </vs-button>
                  <vs-button  class="ml-2" color="secondary" @click="purchaseCard()">{{ $t('Buy') }}</vs-button>
                  </div>
              </div>
            </div>
<!--            <div class="p-6 flex flex-centered" v-if="showPanInput">-->
<!--              <vs-row class="" vs-align="center" vs-type="flex" vs-justify="space-around" vs-w="12" >-->
<!--                <vs-col vs-sm="12" vs-lg="12">-->
<!--                  <div class="gift-card-body">-->
<!--                    <h1 class="text-center gift-card-title">{{$t('GiftCards')}}</h1>-->
<!--                    <div class="py-4"></div>-->
<!--                    <div style="text-align: center;">-->
<!--                      <img class="gift-card-image" :src="selectedProduct.product_image"/>-->
<!--                      <p class="gift-card-amount">{{selectedProduct.product_configurations[selectedConfigIndex].value | germanNumberFormat}}</p>-->
<!--                    </div>-->
<!--                    <div class="py-4"></div>-->

<!--                    <div class="py-4"></div>-->
<!--                    <vs-input autofocus class="w-full gift-input-holder" type="text"-->
<!--                              v-validate="'required'" validate-on="change"-->
<!--                              :label-placeholder="$t('EnterBachNo')" v-model="giftCardBatchNo"/>-->
<!--                    <div class="py-8"></div>-->
<!--                    <div class="vs-row flex justify-center">-->
<!--                      <vs-button size="large" class="ml-4  mr-4" color="danger" @click="onCloseProductDialog()">{{-->
<!--                          $t('Close')-->
<!--                        }}-->
<!--                      </vs-button>-->
<!--                      <vs-button class="btn-padding" color="primary" @click="purchaseCard()">Activate</vs-button>-->
<!--                    </div>-->
<!--                    <div class="w-full text-center">-->
<!--                    </div>-->
<!--                    <div class="py-4"></div>-->
<!--                  </div>-->
<!--                </vs-col>-->
<!--              </vs-row>-->
<!--            </div>-->
          </div>
      </vs-sidebar>
    </div>

    <vx-modal v-if="showPurchaseDataModal" component-class="modal-container-7">
      <template slot="body">
        <div v-if="purchaseData">
          <div style="max-height: 500px; overflow-y: auto;">
<!--            <div class="text-center status-card" v-if="!purchaseData.is_checked">-->
<!--              <div class="status-image origin" style="transform-origin: center center; margin: auto;">-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" width="201.221" height="281.309" viewBox="0 0 201.221 281.309">-->
<!--                  <g id="Group_5693" data-name="Group 5693" transform="translate(-168.643 0.5)">-->
<!--                    <path id="Path_9241" data-name="Path 9241" d="M323.038,128.331a8.76,8.76,0,0,0,6.2-2.593l16.048-16.013a43.621,43.621,0,0,0,12.789-30.957V72.76a8.759,8.759,0,0,0-8.76-8.76H296.759A8.759,8.759,0,0,0,288,72.76v6.027a43.484,43.484,0,0,0,12.824,30.957l16.013,16.013A8.759,8.759,0,0,0,323.038,128.331ZM305.659,81.519l34.758.07a26.1,26.1,0,0,1-7.533,15.767l-9.846,9.828-9.828-9.9A26.03,26.03,0,0,1,305.659,81.519Z" transform="translate(-53.785 -28.961)" fill="#f7c11b" stroke-width="1" />-->
<!--                    <path id="Path_9242" data-name="Path 9242" d="M359.352,260.287H349.341V223.366a89.44,89.44,0,0,0-26.389-63.69L303.43,140.154l19.522-19.521a89.44,89.44,0,0,0,26.389-63.69V20.022h10.011a10.011,10.011,0,1,0,0-20.022h-180.2a10.011,10.011,0,1,0,0,20.022h10.011V56.863a90.68,90.68,0,0,0,26.349,63.73l19.562,19.561-19.562,19.561a90.68,90.68,0,0,0-26.349,63.73v36.841H179.154a10.011,10.011,0,1,0,0,20.022h180.2a10.011,10.011,0,1,0,0-20.022Zm-30.033,0H209.187V223.446a70.557,70.557,0,0,1,20.482-49.575l26.65-26.629a10.012,10.012,0,0,0,0-14.175l-26.65-26.629a70.557,70.557,0,0,1-20.482-49.575V20.022H329.319V56.943A69.539,69.539,0,0,1,308.8,106.477l-26.61,26.589-.018.018a10.013,10.013,0,0,0,0,14.14l.018.018,26.61,26.589a69.539,69.539,0,0,1,20.522,49.535Z" fill="#f7c11b" stroke-width="1" />-->
<!--                    <path id="Path_9243" data-name="Path 9243" d="M329.24,333.089a8.734,8.734,0,0,0-12.4-.017l-16.013,16.012A43.484,43.484,0,0,0,288,380.041v6.027a8.759,8.759,0,0,0,8.76,8.76h52.558a8.759,8.759,0,0,0,8.76-8.76v-6.009A43.621,43.621,0,0,0,345.288,349.1Zm-23.581,44.219a26.03,26.03,0,0,1,7.551-15.767l9.828-9.9,9.846,9.828a26.105,26.105,0,0,1,7.533,15.767Z" transform="translate(-53.785 -149.557)" fill="#f7c11b" stroke-width="1" />-->
<!--                  </g>-->
<!--                </svg>-->
<!--              </div>-->
<!--              <h4>{{$t('TransactionPending')}}</h4>-->
<!--            </div>-->
            <div class="text-center status-card" v-if="purchaseData.message_type == 1">
<!--              <div class="status-image">-->
<!--                <svg style="margin: auto;display: block" xmlns="http://www.w3.org/2000/svg" width="289.944" height="276.917" viewBox="0 0 289.944 276.917">-->
<!--                  <path id="Path_9271" data-name="Path 9271" d="M570.65,427.6l-24-31.728.774-39.782a11.32,11.32,0,0,0-8.041-11.057L501.3,333.5l-22.722-32.663a11.327,11.327,0,0,0-13.022-4.231l-37.581,13.079-37.586-13.079a11.326,11.326,0,0,0-13.02,4.231L354.646,333.5l-38.081,11.535a11.32,11.32,0,0,0-8.041,11.057l.774,39.782-24,31.728a11.323,11.323,0,0,0,0,13.666L309.3,473l-.774,39.782a11.322,11.322,0,0,0,8.041,11.057l38.081,11.537,22.722,32.661a11.324,11.324,0,0,0,13.02,4.229l37.586-13.077,37.583,13.077a11.324,11.324,0,0,0,13.02-4.229L501.3,535.371l38.081-11.535a11.323,11.323,0,0,0,8.041-11.057L546.646,473l24-31.728a11.323,11.323,0,0,0,0-13.666Zm-44.435,34.855a11.334,11.334,0,0,0-2.292,7.055l.682,35.132-33.64,10.189a11.324,11.324,0,0,0-6.015,4.371l-20.063,28.842L431.694,536.5a11.285,11.285,0,0,0-7.445,0l-33.193,11.547L370.994,519.2a11.323,11.323,0,0,0-6.016-4.371l-33.641-10.189.683-35.132a11.324,11.324,0,0,0-2.292-7.052l-21.2-28.024,21.2-28.025a11.308,11.308,0,0,0,2.292-7.052l-.683-35.132,33.641-10.189a11.336,11.336,0,0,0,6.016-4.371l20.063-28.844,33.2,11.549a11.329,11.329,0,0,0,7.443,0l33.193-11.549,20.066,28.844a11.353,11.353,0,0,0,6.013,4.371l33.64,10.189-.682,35.132a11.321,11.321,0,0,0,2.292,7.05l21.2,28.027ZM486.26,392.907a11.329,11.329,0,0,1,0,16.018l-67.04,67.038a11.331,11.331,0,0,1-16.018,0l-33.519-33.517A11.326,11.326,0,1,1,385.7,426.428l25.511,25.509,59.03-59.03a11.325,11.325,0,0,1,16.018,0Zm0,0" transform="translate(-283 -295.977)" fill="#24bc00"/>-->
<!--                </svg>-->
<!--              </div>-->
<!--              <h4>{{$t('TransactionSuccessful')}}</h4>-->


              <div v-if="purchaseData">
                <receipt-print
                  v-on:close="onCloseProductDialog()"
                  ref="printCard"
                  :print-report-type="4" :purchase-data="purchaseData"></receipt-print>
              </div>
            </div>
            <div class="text-center status-card" v-if="purchaseData.message_type == 0">
              <div class="status-image">
                <svg style="margin: auto;display: block" xmlns="http://www.w3.org/2000/svg" width="241.43" height="241.43" viewBox="0 0 241.43 241.43">
                  <g id="Group_5694" data-name="Group 5694" transform="translate(-284 -284)">
                    <path id="Path_2360" data-name="Path 2360" d="M404.715,284C338.242,284,284,338.251,284,404.715S338.251,525.43,404.715,525.43,525.43,471.178,525.43,404.715,471.178,284,404.715,284Zm0,227.284A106.569,106.569,0,1,1,511.284,404.715,106.689,106.689,0,0,1,404.715,511.284Zm0,0" fill="#ff000d"/>
                    <path id="Path_2361" data-name="Path 2361" d="M435,456.219A21.219,21.219,0,1,0,456.219,435,21.244,21.244,0,0,0,435,456.219Zm21.219-7.073a7.073,7.073,0,1,1-7.073,7.073,7.082,7.082,0,0,1,7.073-7.073Zm0,0" transform="translate(-79.797 -79.797)" fill="#ff000d"/>
                    <path id="Path_2362" data-name="Path 2362" d="M576.219,435a21.219,21.219,0,1,0,21.219,21.219A21.244,21.244,0,0,0,576.219,435Zm0,28.293a7.073,7.073,0,1,1,7.073-7.073,7.082,7.082,0,0,1-7.073,7.073Zm0,0" transform="translate(-143.212 -79.797)" fill="#ff000d"/>
                    <path id="Path_2363" data-name="Path 2363" d="M431.22,377.072a7.069,7.069,0,0,0-10,0l-14.146,14.146a7.072,7.072,0,0,0,10,10l14.146-14.146a7.069,7.069,0,0,0,0-10Zm0,0" transform="translate(-63.943 -48.09)" fill="#ff000d"/>
                    <path id="Path_2364" data-name="Path 2364" d="M627.074,377.072a7.072,7.072,0,0,0-10,10l14.146,14.146a7.072,7.072,0,0,0,10-10Zm0,0" transform="translate(-174.92 -48.09)" fill="#ff000d"/>
                    <path id="Path_2365" data-name="Path 2365" d="M442.073,555a7.073,7.073,0,0,0,0,14.146,49.568,49.568,0,0,1,49.512,49.512,7.073,7.073,0,1,0,14.146,0A63.731,63.731,0,0,0,442.073,555Zm0,0" transform="translate(-79.797 -143.212)" fill="#ff000d"/>
                  </g>
                </svg>
              </div>
              <h4>{{$t('TransactionFailed')}}</h4>
              <h3>{{ purchaseData.message }}</h3>
            </div>
          </div>
          <div class="vs-row flex justify-center mt-3" v-if="purchaseData.message_type != 1">
            <vs-button class="" color="primary" @click="onCloseProductDialog()">{{ $t('Close') }}</vs-button>
          </div>
        </div>
      </template>
    </vx-modal>
  </div>

</template>

<script>
import VxModal from '@/layouts/components/custom/VxModal'
import ReceiptPrint from '@/views/components/ReceiptPrint'

export default {
  name: 'GiftCards',
  components: {
    ReceiptPrint,
    VxModal,
  },
  data() {
    return {
      errorFetching: false,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Gift Cards', i18n: 'GiftCards', active: true },
      ],
      searchText: '',
      selectedAlpha: null,
      selectedAlphaCurrentX: 1,
      alpha: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      selectedProduct: null,
      showProductDialog: false,
      totalBill: 0,
      discount: 0,
      profit: 0,
      selectedConfigIndex: null,
      giftCardBatchNo: '',
      showPanInput: false,
      showPurchaseDataModal: false,
      purchaseData: null,
    }
  },
  computed: {
    giftCards() {
      return this.$store.state.giftCards.giftCards.filter((c) => c.product_name.startsWith(this.searchText))
    },
  },
  methods: {
    onProductSelected(p) {
      this.selectedProduct = p
      console.log(this.selectedProduct)
      this.showProductDialog = true
    },
    onCloseProductDialog() {
      this.showProductDialog = false
      this.selectedProduct = null
      this.showPanInput = false
      this.selectedConfigIndex = null
      this.giftCardBatchNo = ''
      this.purchaseData = null
      this.showPurchaseDataModal = false
      this.totalBill = 0
      this.discount = 0
      this.profit = 0

      this.fetchAllGiftCards()
    },
    setProductConfigSelected(index) {
      if (this.selectedConfigIndex == index) {
        this.selectedConfigIndex = null
      } else {
        this.selectedConfigIndex = index
      }
      this.calculateBill()
    },
    pageChanged(newIndex) {
      if (isNaN(newIndex)) {
        this.selectedAlpha = newIndex
        this.selectedAlphaCurrentX = newIndex
        this.searchText = this.selectedAlphaCurrentX
      }
    },
    clearSelectedAlpha() {
      this.selectedAlpha = null
      this.selectedAlphaCurrentX = 1

      this.searchText = ''
    },
    calculateBill() {
      if (this.selectedConfigIndex == null) {
        this.totalBill = 0
        this.discount = 0
        this.profit = 0
      } else {
        this.totalBill = Number(this.selectedProduct.product_configurations[this.selectedConfigIndex].value)
        this.discount = Number(this.selectedProduct.product_configurations[this.selectedConfigIndex].discount)
        const percent = ((this.totalBill) * (this.discount / 100)).toFixed(2)
        this.profit = percent
      }
    },
    calculateProfit(amount, commission) {
      return (Number(amount) * (Number(commission) / 100)).toFixed(2)
    },
    showEntryOptions() {
      this.showPanInput = true
    },
    purchaseCard() {
      if (this.selectedConfigIndex == null) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please select denomination',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      if (this.giftCardBatchNo == '') {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please fill batch no',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      const data = {
        product_configuration_id: this.selectedProduct.product_configurations[this.selectedConfigIndex].id,
        card_type: 1,
        pan_card: this.giftCardBatchNo,
        quantity: 1,
      }
      this.showProductDialog = false
      this.$vs.loading()
      this.$store.dispatch('giftCards/purchaseCard', data)
        .then((data) => {
          this.$vs.loading.close()
          this.purchaseData = data
          this.showPurchaseDataModal = true
          this.checkPrintCardPop()
        })
        .catch((error) => {
          console.log(error)
          this.onCloseProductDialog()
          this.$vs.loading.close()
          if (error.response.data) {
            this.purchaseData = error.response.data
            this.showPurchaseDataModal = true
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },

    checkPrintCardPop() {
      const showPrintPop = localStorage.getItem('show_print_pop') || 'false'
      if (showPrintPop != 'true') {
        setTimeout(() => {
          this.$refs.printCard.printCard()
        }, 1000)
      }
    },
    fetchAllGiftCards() {
      this.$vs.loading()
      this.$store.dispatch('giftCards/fetchGiftCards', {})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = error.response.data.message
          } catch (err) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    }
  },
  created() {
    this.fetchAllGiftCards()
  },
}
</script>

<style scoped>
.modal-container {
  border-radius: 30px !important;
}
.card-image {
  max-height: 48px;
  object-fit: cover;
  margin: auto;
}

.modal-card-image {
  max-height: 120px;
  background: white;
  margin: 0 auto;
  border-radius: 12px;
}

.item-card-text {
  font-size: 24px;
  font-weight: bolder;
}

.item-card-amount {
  font-size: 36px;
  padding: 0 10px;
}

.card-billing-text {
  font-size: 20px;
  font-weight: bold;
}

.card-billing-info {
  font-weight: normal !important;
}
.item-card-text {
  font-size: 24px;
  font-weight: bolder;
}
.item-card-profit {
  text-align: center;
  font-size: 14px;
  font-weight: bolder;
  margin: auto;
  color: #2c2c2c;
}
.theme-dark .item-card-profit {
  color: #ffffff73 !important;
}
.product-selected {
  background-color: #44D7B6 !important;
  color: #ffffff !important;
}
.product-selected .item-card-profit {
  color: #ffffff !important;
}
.product-selected .item-card-text {
  color: #ffffff !important;
}

.gift-card-title {
  font-size: 2.5rem;
}
p.gift-card-amount {
  margin: auto;
  text-align: center;
  font-size: 2.5rem;
  font-weight: bolder;
}
img.gift-card-image {
  width: 200px;
  margin: auto;
}
</style>
